import { UserRoles } from "@/enums/UserRoles";
import { UserTypesLabel } from "@/constants/user/UserTypesLabel";

export const UserRoleLabel = {
  [UserRoles.customer]: UserTypesLabel.customer,
  [UserRoles.manufacturer]: UserTypesLabel.mfr,
  [UserRoles.superAdmin]: "Super Admin",
  [UserRoles.customerServiceAdmin]: "Customer Service Admin",
  [UserRoles.purchaseTeamAdmin]: "Supply Chain Admin",
  [UserRoles.costingEngineer]: "Costing Engineer",
  [UserRoles.technicalSolutionEngineer]: "Technical Solution Engineer",
  [UserRoles.quoteAuthorizer]: "Quote Authorizer",
  [UserRoles.accountManager]: "Account Manager",
  [UserRoles.quoteViewer]: "Quote Viewer",
  [UserRoles.uploadDataAdmin]: "Upload Data Admin",
  [UserRoles.engineeringTeam]: "Engineering Team",
  [UserRoles.technicalFeedbackViewer]: "Technical Feedback Viewer",
};
